import React from 'react';

const PROMO_FEATURES = [
  'First Draft PRO (8 Credits per month)',
  '200,000 AI-generated words per month',
  'Blog Booster (Blog Post Improver)',
  'Social Media Content Generator',
  'Unlimited Keyword Searches (Powered by Semrush)',
  'Quick Draft',
  'Nova Chat',
  'Smart Web Navigator (AI agents)',
  'Plagiarism Checker up to 20,000 words per month',
  'AI Fact Checker',
  'Access for up to 4 team members',
  'Ability to create expanded FAQ-style outlines',
  'Personalized blog post status settings',
  "Team Management (Limit team members' word usage)",
];

const BlackFridayPromo = () => {
  return (
    <div className="bg-gradient-to-r from-purple-900 to-purple-700 rounded-xl p-6 mb-8 text-white relative overflow-hidden">
      {/* Black Friday Badge */}
      <div className="absolute top-4 right-4 bg-yellow-400 text-black font-bold py-1 px-3 rounded-full transform rotate-12">
        BLACK FRIDAY
      </div>

      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold mb-2">
          🎉 Lifetime Deal - Special Offer
        </h2>

        {/* Price Section */}
        <div className="flex items-center gap-4 mb-4">
          <div className="text-4xl font-bold">$179</div>
          <div className="flex flex-col">
            <span className="text-xl line-through text-gray-300">
              Regular $1,099
            </span>
            <span className="text-yellow-400 font-semibold">
              84% OFF - One-time payment
            </span>
          </div>
        </div>

        <p className="text-xl mb-6">Get lifetime access - Never pay again!</p>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 gap-4 mb-6">
          {PROMO_FEATURES.map((feature, index) => (
            <div key={index} className="flex items-center gap-x-2">
              <svg
                className="w-5 h-5 text-yellow-400 flex-shrink-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>{feature}</span>
            </div>
          ))}
        </div>

        {/* Bonus Section */}
        <div className="bg-purple-800 rounded-lg p-4 mb-6">
          <h3 className="text-xl font-semibold mb-2">
            🎁 Free Bonus (Worth $300+)
          </h3>
          <p>1-hour Zoom Call Blog Business Consulting</p>
        </div>

        {/* Timer and CTA Section */}
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between">
          <a
            href="https://buy.bramework.com/b/dR66qAbxG83xc7K14l"
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full md:w-auto text-center bg-bw-green hover:bg-bw-darkGreen text-white font-bold py-3 px-8 rounded-lg transition-colors duration-200 text-lg"
          >
            Yes! I want to Buy for $179
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlackFridayPromo;
