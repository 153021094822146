import React from 'react';
import { connect } from 'react-redux';
import './Plan.scss';
import { updateUser } from '../../store/actions/auth.actions';
import NewWrapper from '../../components/NewWrapper';
import NewHeader from '../../components/NewHeader';
import AddonSection from './AddonSection';

const PLAN_DETAILS = [
  'First Draft PRO (8 Credits included)',
  '200,000 AI-generated words',
  'Blog Booster (Blog Post Improver)',
  'Social Media Content Generator',
  'Quick Draft',
  'Nova Chat',
  'Smart Web Navigator (AI agents)',
  'Plagiarism Checker up to 20,000 words (Powered by Copyscape)',
  'AI Fact Checker',
  'Access for up to 4 team members',
  'FAQ-style outlines',
  'Personalized blog post status',
  'Team Management',
  'Unlimited Keyword Searches (Powered by Semrush)',
  'Copyright free images (Powered by Unsplash)',
  'WordPress integration',
  'Export blogs (PDF, HTML, TXT, DOCX)',
  'Title generator',
  'Outline generator',
  'Paragraph generator',
  'Write and save AI Commands',
  'Rewrite & Summarize',
  'Steps & Listicles',
  'Website Keyword Ranking Tool',
  'Email & live chat support',
];

function BrameworkLTDPlan({ user, updateUser }) {
  return (
    <NewWrapper header={<NewHeader />}>
      <div className="plans-wrapper has-account-access mb-10">
        <h1 className="plan-title">Your Bramework LTD Plan</h1>
        <div className="plans-list">
          <div className="plan">
            <div className="plan-badge">Bramework LTD</div>
            <div className="my-3">
              {PLAN_DETAILS.map((detail, index) => (
                <div key={index} className="flex items-center gap-x-2 my-3">
                  <div>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.37288 9.00107L7.52157 11.1498L11.819 6.85238M15.7583 9.00107C15.7583 12.9567 12.5516 16.1634 8.59592 16.1634C4.64028 16.1634 1.43359 12.9567 1.43359 9.00107C1.43359 5.04543 4.64028 1.83875 8.59592 1.83875C12.5516 1.83875 15.7583 5.04543 15.7583 9.00107Z"
                        stroke="#883AE1"
                        strokeWidth="1.40104"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="plan-description">{detail}</div>
                </div>
              ))}
            </div>
            <div className="btn-wrapper">
              <button className="w-full cursor-default flex justify-center py-2 px-4 border border-purple-600 rounded-lg shadow-sm text-md font-medium text-purple-600 bg-white">
                Current
              </button>
            </div>
          </div>
        </div>
        <AddonSection user={user} updateUser={updateUser} />
      </div>
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user || {},
  };
}

export default connect(mapStateToProps, { updateUser })(BrameworkLTDPlan);
