import React, { useEffect, useState } from 'react';
import './Register.scss';
import { Link, useLocation } from 'react-router-dom';
import Validator, {
  email,
  full_name,
  required,
  confirm,
} from '../services/Validator';
import classNames from 'classnames';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import Loading from '../components/Loading';
import { onSignIn, track } from '../services/track';
import { useAlert } from 'react-alert';
import http from '../services/http';
import logoIconPurple from '../styles/images/logo-icon-purple.svg';
import appSumoTacoWhite from '../styles/images/as-taco-only.svg';
import googleIcon from '../styles/images/google-icon.svg';
import { PlusIcon } from '@heroicons/react/outline';
import JaniaHeadshot from '../styles/images/jania-headshot.jpg';
import staceyHeadshot from '../styles/images/stacey-headshot.png';
import marcellusHeadshot from '../styles/images/marcellus-headshot.png';
import paigeHeadshot from '../styles/images/paige-headshot.png';
import rockyHeadshot from '../styles/images/rocky-headshot.png';

const TESTIMONIALS = [
  {
    image: staceyHeadshot,
    name: 'Stacy Hibiscus',
    quote:
      "Plagiarism - no way! There are more and more free AI tools out there, but Bramework, for me, is a far superior tool. Not only in helping me construct and write great blogs, or rewrite them as the case often is, but what I like most about it is the 'plagiarism' button. I feel safe that anything going out isn't out there already. Love it and will continue to use and recommend.",
  },
  {
    image: marcellusHeadshot,
    name: 'Marcellus',
    quote:
      "What AN AMAZING TOOL!!! OMG!!! This is one that I have to say is amazing. I can't tell you how much I love this software and the power of what it can do. I mean, it kicks out content like nothing I have seen. Everything is located in the tool…from keyword research to so many other things. I think this is a tool that people are going to hate that they didn't get.",
  },
  {
    image: paigeHeadshot,
    name: 'Paige Battcher',
    quote:
      "So simple it's fun to use! I'm stoked about this and cannot wait to share with other people. I've previously tried Frase, Jasper, WriterZen, and some others that I cannot even remember the names of. Bramework hands-down wins on multiple fronts! It's easy to navigate. It's incredibly simple and yet powerful. I love the sorts of software that I can get in and take action immediately. Then I can watch more trainings to dive deeper. Bravo!!",
  },
  {
    image: rockyHeadshot,
    name: 'Rocki Howard',
    quote:
      'My Favorite AI Writing Tool. I have been testing several AI writing tools including Blogely and Wordplay. The one that is working the best for me for writing my blog is hands down Bramework. I can build blogs in two ways. I can use keywords, create an outline, and then fill in my thoughts and point of view. The awesome thing is the AI picks up on my voice and can help me to build out my blog.',
  },
];

const METRICS = [
  { label: 'Bloggers and Agencies', value: '6,000+' },
  { label: 'Countries', value: '80+' },
  { label: 'Blog Posts', value: '225,000+' },
  { label: 'AI-Generated Words', value: '330M+' },
];

function StarRating() {
  return (
    <div className="flex justify-center space-x-1 mb-4">
      {[...Array(5)].map((_, i) => (
        <svg
          key={i}
          className="w-5 h-5 text-yellow-400"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      ))}
    </div>
  );
}

function TestimonialCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex(current => (current + 1) % TESTIMONIALS.length);
        setIsTransitioning(false);
      }, 500);
    }, 6000);
    return () => clearInterval(timer);
  }, []);

  const handleDotClick = index => {
    if (index === currentIndex) return;
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <div className="testimonial-section">
      <div className="testimonial-carousel">
        <div className="carousel-container">
          <div
            className={`transition-all duration-500 ${
              isTransitioning ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
            }`}
          >
            <div className="profile-image-container">
              <div className="profile-ring" />
              <img
                src={TESTIMONIALS[currentIndex].image}
                alt={TESTIMONIALS[currentIndex].name}
                className="profile-image"
              />
            </div>

            <StarRating />

            <div className="testimonial-content">
              <p className="quote">{TESTIMONIALS[currentIndex].quote}</p>
              <p className="author">- {TESTIMONIALS[currentIndex].name}</p>
            </div>
          </div>

          <div className="navigation">
            {TESTIMONIALS.map((_, idx) => (
              <button
                key={idx}
                className={`nav-dot ${idx === currentIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(idx)}
                aria-label={`Go to testimonial ${idx + 1}`}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="metrics-grid">
        {METRICS.map((metric, index) => (
          <div key={index} className="metric-item">
            <div className="value">{metric.value}</div>
            <div className="label">{metric.label}</div>
          </div>
        ))}
      </div>

      <div className="tagline">
        The go-to solution for creators and freelancers, offering everything
        needed to build and grow exceptional brands online.
      </div>
    </div>
  );
}

function RegisterForm({ validator }) {
  const auth = useSentData();
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const invoice = new URLSearchParams(search).get('invoice');
  const query = new URLSearchParams(search);
  const invite = query.get('invite');
  const [data, setData] = useState({
    full_name: '',
    email: email ? email : '',
    password: '',
    password_confirm: '',
    confirm: false,
    invite: invite || '',
  });
  const [errors, setErrors] = useState({
    full_name: false,
    email: false,
    password: false,
    password_confirm: false,
    confirm: false,
  });
  const alert = useAlert();
  useEffect(() => {
    if (invite) {
      setData({ ...data, invite });
    }
  }, [invite]);

  function change(name, val) {
    const newData = {
      ...data,
      [name]: val,
    };
    setData(newData);
    setErrors(validator.validateAll(newData));
  }

  return (
    <div className="flex min-h-full register-wrapper">
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 w-full lg:w-1/2">
        {!!!invoice ? (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src={logoIconPurple}
              alt="Bramework"
            />
            <h2 className="mt-6 text-center text-xl lg:text-2xl font-extrabold text-gray-900">
              {/* Start your free 7-day trial and write blog posts 5X faster! */}
              Start writing high-quality long-form blog posts in a few clicks!
            </h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              No credit card required.
            </p> */}
          </div>
        ) : (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="flex mx-auto justify-between w-60">
              <img className="h-12" src={logoIconPurple} alt="Bramework" />
              <PlusIcon
                className="h-8 w-8 text-gray-400 mt-2"
                aria-hidden="true"
              />
              <img className="h-12" src={appSumoTacoWhite} alt="AppSumo" />
            </div>

            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create a New Account
            </h2>
          </div>
        )}
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              onSubmit={e => {
                e.preventDefault();
                if (validator.isValid()) {
                  auth
                    .send('/api/registration', { ...data, invoice: invoice })
                    .then(data => {
                      if (!data.isAxiosError) {
                        const token = data.token;
                        http.defaults.headers.common.Authorization = `JWT ${token}`;
                        const user = data.user;
                        saveAuth(token, user);
                        window.location.href = '/';
                        window.fpr('referral', {
                          email: user.email,
                        });
                        var date = new Date(user.date_joined);
                        var unixTimeStamp = Math.floor(date.getTime() / 1000);
                        if (process.env.REACT_APP_INTERCOM_ID) {
                          window.Intercom('boot', {
                            app_id: process.env.REACT_APP_INTERCOM_ID,
                            name: user.full_name, // Full name
                            email: user.email, // Email address
                            user_id: user.id, // User id
                            created_at: unixTimeStamp, // Signup date as a Unix timestamp
                            sumo_ling: !!!invoice ? false : true,
                          });
                        }
                        window.profitwell('user_email', user.email);
                        if (
                          window &&
                          window.mixpanel &&
                          window.mixpanel.identify &&
                          window.mixpanel.people &&
                          window.mixpanel.people.set
                        ) {
                          window.mixpanel.alias(user.email);
                          track('Sign up');
                        }
                      } else {
                        alert.error(isolateError(data));
                      }
                    });
                }
              }}
            >
              {auth.loading && <Loading />}
              <input type="hidden" name="invite" value={invite} />
              <div>
                <label
                  htmlFor="full_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full name
                </label>
                <div className="mt-1">
                  <input
                    id="full_name"
                    name="full_name"
                    type="text"
                    autoComplete="full_name"
                    value={data.full_name}
                    onChange={e => change('full_name', e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    disabled={!!email}
                    value={data.email}
                    onChange={e => change('email', e.target.value)}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={data.password}
                    onChange={e => change('password', e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password_confirm"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="password_confirm"
                    name="password_confirm"
                    type="password"
                    value={data.password_confirm}
                    onChange={e => change('password_confirm', e.target.value)}
                    required
                    aria-invalid={errors.password_confirm}
                    aria-describedby="password-confirm-error"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  />
                  {errors.password_confirm && (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="password-confirm-error"
                    >
                      {errors.password_confirm}
                    </p>
                  )}
                </div>
              </div>

              {!!!invoice && (
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    Already have an account?&nbsp;
                    <Link
                      className="font-medium text-purple-600 hover:text-purple-500"
                      to="/"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              )}

              <div>
                <button
                  id="submit"
                  disabled={
                    !errors.email ||
                    !errors.password ||
                    !errors.full_name ||
                    !errors.password_confirm
                  }
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                    !errors.full_name ||
                    !errors.email ||
                    !errors.password ||
                    !errors.password_confirm
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                >
                  Get started
                </button>
              </div>
            </form>

            <div className="mt-6">
              {!!!(invoice || invite || true) && (
                <>
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">Or</span>
                    </div>
                  </div>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-sm text-sm font-medium border border-gray-300  bg-white text-gray-700 hover:bg-gray-50 cursor-pointer"
                    >
                      <img
                        className="mr-4 w-5 h-5"
                        src={googleIcon}
                        alt="Google icon"
                        aria-hidden="true"
                      />
                      Continue with Google
                    </button>
                  </div>
                </>
              )}

              <div className="mt-6">
                <p className="text-center text-xs text-gray-500">
                  Bramework will never sell any personal information to third
                  parties By signing up, I agree to Bramework's&nbsp;
                  <a
                    className="font-medium text-purple-600 hover:text-purple-500"
                    href="https://www.bramework.com/terms-of-use/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    className="font-medium text-purple-600 hover:text-purple-500"
                    href="https://www.bramework.com/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Privacy Policy.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden min-h-full lg:flex flex-col md:w-1/2 items-center justify-center bg-purple-600 p-8">
        <TestimonialCarousel />
      </div>
    </div>
  );
}

function Register() {
  const validator = Validator({
    full_name: [required, full_name],
    email: [required, email],
    password: [required],
    password_confirm: [required, confirm('password')],
    // confirm: [required],
  });

  return <RegisterForm validator={validator} />;
}
function saveAuth(token, user) {
  onSignIn(user);
  localStorage.setItem('access_token', token);
  localStorage.setItem('user', JSON.stringify(user));
}
export default Register;
